<template>
  <div class="container">
    <breadcrumb :breadcrumbList="breadMembersCrumbList"></breadcrumb>
    <div class="main-content-title">
      <div class="bg">
        <div class="left">
          <div class="border"></div>
          <p>启用成长值</p>
          <span>启用后，会员等级随成长值变化，可针对单个会员手动调整和锁定等级</span>
        </div>
        <el-switch v-model="configData.enabled" />
      </div>
    </div>
    <div class="main-content">
      <el-table :data="configData.pointsGrowSettingItemList" border :span-method="objectSpanMethod">
        <el-table-column prop="taskType" label="任务种类" align="center">
          <template #default="scope">
            <p v-if="scope.row.taskType == 1">基础任务</p>
            <p v-if="scope.row.taskType == 2">购物任务</p>
            <p v-if="scope.row.taskType == 3">活跃任务</p>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="成长任务" align="center">
          <template #default="scope">
            <p v-if="scope.row.type == 1">完善信息</p>
            <p v-if="scope.row.type == 2">购买卡项</p>
            <p v-if="scope.row.type == 3">购买服务</p>
            <p v-if="scope.row.type == 4">购买产品</p>
            <p v-if="scope.row.type == 5">完成预约</p>
          </template>
        </el-table-column>
        <el-table-column prop="tripartiteServeName" label="支付方式" align="center">
          <template #default="scope">
            <p v-if="scope.row.taskType == 2">所有支付方式</p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column prop="points" label="获得成长值" align="center">
          <template #default="scope">
            <el-input-number :controls="false" :min="0" v-model="scope.row.points" />
            <span style="margin-left: 10px;">点<span :style="scope.row.taskType == 2 ? '' : 'opacity:0;'">/元</span></span>
          </template>
        </el-table-column>
        <el-table-column prop="hasMaximums" label="日上限" align="center">
          <template #default="scope">
            <el-radio-group v-model="scope.row.hasMaximums">
              <el-radio :label="false">无上限</el-radio>
              <el-radio :label="true">
                <el-input-number :disabled="!scope.row.hasMaximums" :min="0" v-model="scope.row.maximums"
                  :controls="false" style="width: 100px;" />
                点/日
              </el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom">
        <el-button style="margin-right: 20px;" @click="cancel">取消</el-button>
        <el-button @click="handleSaveClick()" type="primary">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, nextTick, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadMembersCrumbList } from './config'
import { useRouter } from 'vue-router'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'
import { savePointsGrowSetting, getPointsGrowSetting } from '@/service/main/member'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
  },
  setup() {
    const router = useRouter()
    const configData = ref({})
    const objectSpanMethod = ({
      row,
      column,
      rowIndex,
      columnIndex,
    }) => {
      if (columnIndex === 0) {
        const _row = oneRow()[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    }
    const oneRow = () => {
      const spanOne = []
      let pos = 0
      configData.value.pointsGrowSettingItemList.forEach((ele, i) => {
        if (i === 0) {
          spanOne.push(1)
          pos = 0
        } else {
          if (ele.taskType === configData.value.pointsGrowSettingItemList[i - 1].taskType) {
            spanOne[pos] += 1
            spanOne.push(0)
          } else {
            spanOne.push(1)
            pos = i
          }
        }
      })
      return [...spanOne]
    }

    const cancel = () => {
      router.back()
    }

    const handleSaveClick = () => {
      savePointsGrowSetting(configData.value).then(res => {
        ElMessage({
          message: '保存成功',
          type: 'success'
        })
      })
      cancel()
    }

    const getConfigData = async () => {
      let res = await getPointsGrowSetting()
      configData.value = res.data
    }
    getConfigData()
    return {
      breadMembersCrumbList,
      configData,
      objectSpanMethod,
      cancel,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.container {
  min-width: 1080px;

  .main-content,
  .main-content-title {
    padding: 15px;
    margin: 20px;
    background-color: #fff;
  }

  .main-content-title {
    .bg {
      background-color: #F9F5FE;
      display: flex;
      padding: 15px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        .border {
          width: 5px;
          height: 20px;
          background-color: #7948ea;
          margin-right: 10px;
        }
      }

      span {
        color: #C4C2C9;
        font-size: 13px;
        margin-left: 10px;
      }
    }
  }

  .main-content {
    min-height: 80vh;
    padding: 30px 20px;
    position: relative;

    &:deep(.el-table) {
      th.el-table__cell {
        background-color: rgba(239, 239, 239, 1) !important;

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

      .el-table__header-wrapper {

        .el-table__cell {

          background-color: rgba(239, 239, 239, 1);
          // padding: 9px 0 !important;
          // border:1px solid rgb(223, 223, 223);

          .cell {
            color: rgba(80, 80, 80, 1) !important;
            font-size: 14px !important;
            font-weight: 500 !important;
          }
        }

      }

      .cell {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>