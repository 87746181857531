import { request4 } from '@/service/request4'

//获取积分会员列表
export function getPointsMemberList(data) {
  return request4({
    url: '/pointsMember/web/getPointsMemberList',
    method: 'post',
    data
  })
}

//删除积分会员
export function deletePointsMember(id) {
    return request4({
    url: `/pointsMember/web/deletePointsMember?id=${id}`,
    method: 'get'
    })
}

//创建积分会员
export function createPointsMember(data) {
    return request4({
      url: '/pointsMember/web/createPointsMember',
      method: 'post',
      data
    })
}

//获取积分会员详情
export function getPointsMember(id) {
    return request4({
    url: `/pointsMember/web/getPointsMember?id=${id}`,
    method: 'get'
    })
}

//编辑积分会员
export function editPointsMember(data) {
    return request4({
      url: '/pointsMember/web/editPointsMember',
      method: 'post',
      data
    })
}


//保存积分会员等级升降级规则
export function savePointsMemberLevelChangeSetting(data) {
    return request4({
      url: '/pointsMember/web/savePointsMemberLevelChangeSetting',
      method: 'post',
      data
    })
}

//获取积分会员等级升降级规则
export function getPointsMemberLevelChangeSetting() {
    return request4({
    url: `/pointsMember/web/getPointsMemberLevelChangeSetting`,
    method: 'get'
    })
}


//保存积分会员成长设置
export function savePointsGrowSetting(data) {
    return request4({
      url: '/pointsMember/web/savePointsGrowSetting',
      method: 'post',
      data
    })
}

//获取积分会员成长设置
export function getPointsGrowSetting() {
    return request4({
    url: `/pointsMember/web/getPointsGrowSetting`,
    method: 'get'
    })
}


/** 
 * 
 付费会员
 * 
**/
//获取付费会员列表
export function getMonthlyMemberList(data) {
  return request4({
    url: '/monthlyMember/web/getMonthlyMemberList',
    method: 'post',
    data
  })
}

//获取付费会员购买记录
export function getPurchaseMonthlyMemberRecordList(data) {
  return request4({
    url: '/monthlyMember/web/getPurchaseMonthlyMemberRecordList',
    method: 'post',
    data
  })
}
//付费会员退款
export function returnd(params) {
  return request4({
    url: `/monthlyMember/web/returnd`,
    method: 'get',
    params
  })
}

//获取付费会员配置
export function getMonthlyMemberSetting() {
  return request4({
    url: `/monthlyMember/web/getMonthlyMemberSetting`,
    method: 'get'
  })
}

//保存付费会员配置
export function saveMonthlyMemberSetting(data) {
  return request4({
    url: '/monthlyMember/web/saveMonthlyMemberSetting',
    method: 'post',
    data
  })
}


//保存展示设置
export function saveDisplaySetting(data) {
  return request4({
    url: '/monthlyMember/web/saveDisplaySetting',
    method: 'post',
    data
  })
}

//获取展示设置
export function getDisplaySetting(data) {
  return request4({
    url: '/monthlyMember/web/getDisplaySetting',
    method: 'post',
    data
  })
}

//获取推广二维码
export function getEqCode() {
  return request4({
    url: `/monthlyMember/web/getEqCode`,
    method: 'get'
  })
}