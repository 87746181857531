export const breadcrumbList = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '营销列表',
    path: '/main/marketing/list'
  },
  {
    name: '免费会员',
    path: ''
  }
]

export const breadcrumbListForm = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '免费会员',
    path: '/main/member/integral/list'
  },
  {
    name: '添加会员',
    path: ''
  }
]

export const breadMembersCrumbList = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '免费会员',
    path: '/main/member/integral/list'
  },
  {
    name: '管理会员成长任务',
    path: ''
  }
]


